import { Badge } from '@chakra-ui/react';
import * as React from 'react';
//import { User } from './User'

export const data = [
  {
    id: '1181',
    numSx: '20110432',
    giornoSx: '18',
    meseSx: '06',
    annoSx: '2011',
    sp: 'n/s',
    cognomeInt: 'Iannotta',
    nomeInt: 'Tiziana',
    targa: 'no',
    ragSocialeLeg: 'Pisanti Avv.',
    nomeLeg: 'Gennaro',
  },
  {
    id: '1180',
    numSx: 'P2015N056',
    giornoSx: '17',
    meseSx: '02',
    annoSx: '2015',
    sp: '109',
    cognomeInt: '',
    nomeInt: '',
    targa: 'AT568WE',
    ragSocialeLeg: 'Cocca',
    nomeLeg: 'Immacolata',
  },
  {
    id: '1179',
    numSx: 'P2015N055',
    giornoSx: '17',
    meseSx: '02',
    annoSx: '2015',
    sp: '109',
    cognomeInt: 'Carapella ',
    nomeInt: 'Franca',
    targa: 'AT568WE',
    ragSocialeLeg: 'Cocca',
    nomeLeg: 'Immacolata',
  },
  {
    id: '1178',
    numSx: 'P2015N054',
    giornoSx: '11',
    meseSx: '12',
    annoSx: '2014',
    sp: '114',
    cognomeInt: 'Libardi',
    nomeInt: 'Filomena',
    targa: 'EN366PH',
    ragSocialeLeg: 'Viola',
    nomeLeg: 'Oreste',
  },
  {
    id: '1177',
    numSx: 'P2015N053',
    giornoSx: '10',
    meseSx: '02',
    annoSx: '2015',
    sp: '',
    cognomeInt: 'Libardi ',
    nomeInt: 'Giovanni',
    targa: 'BL620GZ',
    ragSocialeLeg: 'Viola',
    nomeLeg: 'Oreste',
  },
];

/* export const data = [
  {
    role: 'Admin',
    status: 'active',
    earned: '$45,000',
    id: 'blog',
    user: {
      image:
        'https://images.unsplash.com/photo-1512485694743-9c9538b4e6e0?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NDN8fGd1eSUyMGZhY2V8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=60',
      name: 'Marion Watson',
      email: 'codyfisher@example.com',
    },
  },
  {
    role: 'Marketing Director',
    status: 'reviewing',
    earned: '$4,840',
    id: 'home',
    user: {
      image:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
      name: 'Louise Hopkins',
      email: 'jane@example.com',
    },
  },
  {
    role: 'Front Desk Officer',
    status: 'declined',
    id: 'design-system',
    earned: '$89,054',
    user: {
      image:
        'https://images.unsplash.com/photo-1470506028280-a011fb34b6f7?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NjN8fGxhZHklMjBmYWNlfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=60',
      name: 'Susan Schwartz',
      email: 'jenyzx@exmaple.com',
    },
  },
  {
    role: 'Lead Software Engineer',
    status: 'active',
    earned: '$19,954',
    id: 'home-2',
    user: {
      image:
        'https://images.unsplash.com/photo-1533674689012-136b487b7736?ixid=MXwxMjA3fDB8MHxzZWFyY2h8Mjl8fGFmcmljYSUyMGxhZHklMjBmYWNlfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=60',
      name: 'Sade Akinlade',
      email: 'melyb@example.com',
    },
  },
] */
const badgeEnum = {
  active: 'green',
  reviewing: 'orange',
  declined: 'red',
};

export const columns = [
  {
    Header: 'Numero Sinistro',
    accessor: 'numSx',
  },
  {
    Header: 'Data',
    accessor: 'annoSx',
    /* accessor: ['giornoSx', 'meseSX', 'annoSx'],
    Cell: function DateCell(data) {
      return <>{`${data[0]}/${data[1]}/${data[2]}`}</>;
    }, */
  },
  {
    Header: 'Intestatario',
    accessor: 'cognomeInt',
    /* accessor: ['cognomeInt', 'nomeInt'],
    Cell: function NameCell(data) {
      return <>{`${data[0]} ${data[1]}`}</>;
    }, */
  },
  {
    Header: 'Targa',
    accessor: 'targa',
  },
  {
    Header: 'Legale',
    accessor: 'ragSocialeLeg',
  },
];

/* export const columns = [
  {
    Header: 'Member',
    accessor: 'user',
    Cell: function MemberCell(data) {
      return <User data={data} />
    },
  },
  {
    Header: 'Role',
    accessor: 'role',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: function StatusCell(data) {
      return (
        <Badge fontSize="xs" colorScheme={badgeEnum[data]}>
          {data}
        </Badge>
      )
    },
  },
  {
    Header: 'Amount Earned',
    accessor: 'earned',
  },
] */
