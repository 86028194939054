import React from 'react';
//import { App as Login } from './chakraUI/Login2/App';
import { App as Login } from './chakraUI/Login2/App';
import { App as Pannello } from './chakraUI/Table/App';
import { App as Form } from './chakraUI/Form/App';
import Layout from './components/Layout';
import RequireAuth from './components/RequireAuth';

import { Routes, Route } from 'react-router-dom';

const ROLES = {
  admin: 'admin',
  read: 'read',
};

function App() {
  return (
    <Routes>
      {/* public routes */}
      <Route path="login" element={<Login />} />
      <Route path="unauthorized" element={<>UNAUTHORIZED</>} />
      {/* <Route path="form" element={<Form />}></Route> */}

      {/* we want to protect these routes */}
      <Route path="/" element={<Layout />}>
        <Route element={<RequireAuth />}>
          <Route path="/" element={<Pannello />} />
          <Route path=":numSx" element={<Form />} />
        </Route>
      </Route>
      {/*  <Route element={<RequireAuth allowedRole={ROLES.read} />}>
        <Route path="/" element={<>READONLY</>} />
      </Route> */}

      {/* catch all */}
      <Route path="*" element={<>404</>} />
    </Routes>
  );
}

export default App;
